/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Reference"}>
        <SiteHeader />

        <Column className="css-wpz77x --style2 --full" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/396697ce042745e1ad3b2c5f2bf37f32_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/396697ce042745e1ad3b2c5f2bf37f32_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/396697ce042745e1ad3b2c5f2bf37f32_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/396697ce042745e1ad3b2c5f2bf37f32_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/396697ce042745e1ad3b2c5f2bf37f32_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/396697ce042745e1ad3b2c5f2bf37f32_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/396697ce042745e1ad3b2c5f2bf37f32_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/396697ce042745e1ad3b2c5f2bf37f32_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" anim={null} style={{"maxWidth":1390}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"5"} style={{"maxWidth":1425}}>
              
              <Title className="title-box title-box--center fs--62 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"Reference"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"r6bu6czs1cm"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/95e54f807f7548abb12c0c205cd60e1e_s=660x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/95e54f807f7548abb12c0c205cd60e1e_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/95e54f807f7548abb12c0c205cd60e1e_s=660x_.JPG 660w"}>
              </Image>

              <Text className="text-box" content={"\"U Kláry bylo naše první hlídání. Klára je velmi příjemná, ochotná a na všem co jsme se dohodli platilo bez kompromisů. Byli jsme s jejím hlídáním moc spokojeni my i naše fenka Československého vlčáka, kterou měla na starosti. Až budeme zase potřebovat hlídání, určitě se na Kláru rádi obrátíme. Děkujeme :-)\" Eva K. (Československý vlčák)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/8a5ff4084ff9480e9a77bbb5b3306058_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/8a5ff4084ff9480e9a77bbb5b3306058_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26402/8a5ff4084ff9480e9a77bbb5b3306058_s=660x_.jpg 660w, https://cdn.swbpg.com/t/26402/8a5ff4084ff9480e9a77bbb5b3306058_s=860x_.jpg 860w, https://cdn.swbpg.com/t/26402/8a5ff4084ff9480e9a77bbb5b3306058_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"\"Klárka je naprosto pohodová, milá, spolehlivá. Určitě ji k hlídání využijeme znovu. Náš pejsek byl moc spokojený a je na něm vidět, že po ní teskní.\"&nbsp;<br>Lenka N.&nbsp;(Bígl)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/d0670f31c9f3405cba94ba2293d68e9c_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/d0670f31c9f3405cba94ba2293d68e9c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26402/d0670f31c9f3405cba94ba2293d68e9c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/26402/d0670f31c9f3405cba94ba2293d68e9c_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box" content={"\"Klára mi chodí venčit dva pejsky - border kolii a křížence. Maximální spokojenost a doporučuji všem :)\"<br>Eva B.&nbsp;(Border kolie a kříženec)<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"3hjbv9212ol"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/1e891f68b9f04d418c2fbfde83b5e2dc_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/1e891f68b9f04d418c2fbfde83b5e2dc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26402/1e891f68b9f04d418c2fbfde83b5e2dc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/26402/1e891f68b9f04d418c2fbfde83b5e2dc_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box" content={"\"Klára hlídala mého temperamentního miláčka - domluva rychlá, korektní, věcná, vše v klidu, péče nadstandardní, prostě 1 s obrovskou hvězdičkou. Mohu služby Klárou nabízené stoprocentně doporučit nejen za sebe, ale především za psa!\" <br>Jana K. (Výmarský ohař)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/1fc23fe568c54334b71076cc0aa345f6_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/1fc23fe568c54334b71076cc0aa345f6_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/1fc23fe568c54334b71076cc0aa345f6_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/1fc23fe568c54334b71076cc0aa345f6_s=860x_.JPG 860w, https://cdn.swbpg.com/t/26402/1fc23fe568c54334b71076cc0aa345f6_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box" content={"\"Slečna Klára dobře komunikovala, sešly jsme se a prošly s pejskem trasu, kterou budou společně chodit. Byla jsem velmi spokojená s celým průběhem venčení.\"\n\n<br>Iveta N.&nbsp;(Maďarský ohař)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/b25ae6508df04945aae70a570beb896a_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/b25ae6508df04945aae70a570beb896a_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/b25ae6508df04945aae70a570beb896a_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/b25ae6508df04945aae70a570beb896a_s=860x_.JPG 860w, https://cdn.swbpg.com/t/26402/b25ae6508df04945aae70a570beb896a_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box" content={"\"Klára je na jedničku! Spolehlivá, řekla bych, že ji jen tak něco nepřekvapí a nevyvede z míry. Je profesionální, přizpůsobivá, ochotná podstoupit i větší výzvu.\"<br>Barbora Š.&nbsp;(Německý ovčák)<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"ko516rdiftj"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/605b862f96d14d9189834a34da9ba685_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/605b862f96d14d9189834a34da9ba685_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/605b862f96d14d9189834a34da9ba685_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/605b862f96d14d9189834a34da9ba685_s=860x_.JPG 860w, https://cdn.swbpg.com/t/26402/605b862f96d14d9189834a34da9ba685_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box" content={"\"Klára je milá a velmi pečlivá. Při hlídání naší temperamentní fenky Sofie se z nich rychle staly parťačky a bylo vidět, že si společný čas užily :). Oceňuji, že nám Klára pravidelně posílala fotky a informace o tom, co zrovna dělají. Budeme se vracet!\"<br>Zdeněk K. (Německý boxer)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/7f147dc83f4c4421a18eb6bbb32aede5_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/7f147dc83f4c4421a18eb6bbb32aede5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26402/7f147dc83f4c4421a18eb6bbb32aede5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/26402/7f147dc83f4c4421a18eb6bbb32aede5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/26402/7f147dc83f4c4421a18eb6bbb32aede5_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center" content={"\"Barbara Klara is just awesome. You can really see that she loves animals. Our dog just loves her. She is really great at sending us update photos. I would totally recommend Klara.\" <br>&nbsp;Barbara W. (Labradorský retrívr)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/99c04db0d15e4c8c9390494a5f404f63_s=660x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/99c04db0d15e4c8c9390494a5f404f63_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/99c04db0d15e4c8c9390494a5f404f63_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/99c04db0d15e4c8c9390494a5f404f63_s=860x_.JPG 860w"}>
              </Image>

              <Text className="text-box" content={"\"Klára je skvělá hlídačka našeho čtyřnohého ohařího kamaráda. Klárka si pejska pravidelně vyzvedává přes oběd a posílá nám fotky jak se pejsek má. Velmi si u Kláry ceníme nejen jejího perfektního přátelského přístupu, ale že spolupráce není jen o venčení, ale i o jejím přístupu k pejskovi a jeho výchově. Přála bych každému mít hlídaní pejska jako nám zajišťuje Klára :-)\"<br>Nikola R. (Maďarský ohař)"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"x154jo0ou2p"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/f0d35c8d85624a2ba6bd5ed54401d022_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/f0d35c8d85624a2ba6bd5ed54401d022_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/f0d35c8d85624a2ba6bd5ed54401d022_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/f0d35c8d85624a2ba6bd5ed54401d022_s=860x_.JPG 860w, https://cdn.swbpg.com/t/26402/f0d35c8d85624a2ba6bd5ed54401d022_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box" content={"\"Moc děkujeme Kláře za ochotu a skvělou domluvu! Budeme se těšit znovu na shledanou!\"<br>Maria M.&nbsp;(Trpasličí pinčové)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/fd2e88b45c5541e5b118dc109f00971f_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/fd2e88b45c5541e5b118dc109f00971f_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/fd2e88b45c5541e5b118dc109f00971f_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/fd2e88b45c5541e5b118dc109f00971f_s=860x_.JPG 860w, https://cdn.swbpg.com/t/26402/fd2e88b45c5541e5b118dc109f00971f_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box" content={"\"Klára je opravdu úžasná \"náhradní panička\". Náš pes Václav byl u ní naprosto spokojený, řekla bych, že byl až smutný, že musí zase domů :-). Klára mi posílala průběžně fotky a zprávy, jak se mají. Je moc milá, spolehlivá a je vidět, že psy miluje a oni jí také.\"<br>Lucie R.&nbsp;(Čivava)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/20ee8e2314ed4a0587d97f0952fc4241_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/20ee8e2314ed4a0587d97f0952fc4241_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/20ee8e2314ed4a0587d97f0952fc4241_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/20ee8e2314ed4a0587d97f0952fc4241_s=860x_.JPG 860w, https://cdn.swbpg.com/t/26402/20ee8e2314ed4a0587d97f0952fc4241_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box" content={"\"Pět hvězdiček je málo :-) Klára je úžasná. Naše fenka s ní prožila dva báječné dny.\"<br>Bohdan H. (Anglický bulteriér)<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"xtkworg5as"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/2aab66a0d9df4593b77878dc0ad8e1ed_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/2aab66a0d9df4593b77878dc0ad8e1ed_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/2aab66a0d9df4593b77878dc0ad8e1ed_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/2aab66a0d9df4593b77878dc0ad8e1ed_s=860x_.JPG 860w, https://cdn.swbpg.com/t/26402/2aab66a0d9df4593b77878dc0ad8e1ed_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box" content={"\"Skvělá domluva, slečna je milá, ukázala nám byt, kde pejsek bude bydlet a po celou dobu pobytu nám psala a zasílala fotky. Při přebírání pejska se za ní ještě zkoušel vrátit, ale už \"bohužel\" musel domů. :)\"<br>Nela D. (Tervueren)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/0190f8d3194f4b40bd12406420866bdf_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/0190f8d3194f4b40bd12406420866bdf_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box" content={"\"I cannot say enough good things about Klara. She is AMAZING! I was very nervous leaving my puppy for the first time, and she made sure to send me plenty of videos and pictures. I could tell the next time that my puppy saw Klara that she was very excited and happy. Klara went above and beyond what I was expecting. She took her on long walks, and gave her baths. Klara treated her like her own puppy. :) :) :)\"<br>Melissa A. (Shiba-Inu)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/85ef562d2dea457fb11ceb77e033e985_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/85ef562d2dea457fb11ceb77e033e985_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/85ef562d2dea457fb11ceb77e033e985_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/85ef562d2dea457fb11ceb77e033e985_s=860x_.JPG 860w, https://cdn.swbpg.com/t/26402/85ef562d2dea457fb11ceb77e033e985_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box" content={"\"Super domluva, vše proběhlo bez problémů. Rádi využijeme hlídání i jindy :-)\"<br>Hana K. (Francouzský buldoček)<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"z9ov1tzetx"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/084317d123054f9994dd9d53615be841_s=660x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/084317d123054f9994dd9d53615be841_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/084317d123054f9994dd9d53615be841_s=660x_.JPG 660w"}>
              </Image>

              <Text className="text-box" content={"\"My dog loved Klara so much! She still looks for her on the street sometimes. That means lots!\"<br>Marika O. (Bobtail)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/25cbaacf00e049e8815fb38e9514f2b5_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/25cbaacf00e049e8815fb38e9514f2b5_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/25cbaacf00e049e8815fb38e9514f2b5_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/25cbaacf00e049e8815fb38e9514f2b5_s=860x_.JPG 860w, https://cdn.swbpg.com/t/26402/25cbaacf00e049e8815fb38e9514f2b5_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box" content={"\"Klára je skvelá a spoľahlivá slečna. Počas stráženia som dostávala fotky aj správy a ja som bola spokojná a videla, že naš Matyas si užije krásny vikend. Budem rada, keď budem môcť využiť stráženie aj v budúcnosti.\"<br>Katarína U. (Stafordširský bulteriér)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/3cce07306d274875a4063d27ddf1e910_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/3cce07306d274875a4063d27ddf1e910_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/3cce07306d274875a4063d27ddf1e910_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/3cce07306d274875a4063d27ddf1e910_s=860x_.JPG 860w, https://cdn.swbpg.com/t/26402/3cce07306d274875a4063d27ddf1e910_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box" content={"\"Klárka byla úžasná. Skvěle se postarala o našeho ďábla. Měla jsem strach z první zkušenosti s hlídáním u někoho cizího, ale hned od první chvíle v tomto případě jsem byla v klidu :). Moc děkuji a těším se na další hlídání. Všem moc doporučuji.\"<br>Simona K. (Americký stafordširský teriér)<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"ghz0qrwdw1"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/c9f7d78fe72c426390eaac8266e55a7d_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/c9f7d78fe72c426390eaac8266e55a7d_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/c9f7d78fe72c426390eaac8266e55a7d_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/c9f7d78fe72c426390eaac8266e55a7d_s=860x_.JPG 860w, https://cdn.swbpg.com/t/26402/c9f7d78fe72c426390eaac8266e55a7d_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box" content={"\"Klara was sitting with your dog for one week and I definitely recommend her! She is highly responsible person, she provides daily reports and photos. Klara built friendly relationships with our dog from the first moment and we were calm during the full week. Klara definitely knows how to communicate with dogs.\"<br>Sveta I. (Švýcarský ovčák)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/2e1dd505e53c4dd6b83e3cacb62555bb_s=660x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/2e1dd505e53c4dd6b83e3cacb62555bb_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/2e1dd505e53c4dd6b83e3cacb62555bb_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/2e1dd505e53c4dd6b83e3cacb62555bb_s=860x_.JPG 860w"}>
              </Image>

              <Text className="text-box" content={"\"Klára nám hlídala pejska týden a byli jsme s ní velmi spokojeni. Byla naprostý profesionál, vstřícná, klidná a pejsek s ní odešel, ani se neohlédl, což jsme ocenili. Bylo vidět, že je v dobrých rukách. Určitě ji využijeme i v budoucnu. Spokojeni jsme byli my a hlavně náš pejsek.\" <br>Marta D. (Boloňský psík)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/9eb6054c2e884c70885ab47f5134e591_s=660x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/9eb6054c2e884c70885ab47f5134e591_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/9eb6054c2e884c70885ab47f5134e591_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/9eb6054c2e884c70885ab47f5134e591_s=860x_.JPG 860w"}>
              </Image>

              <Text className="text-box" content={"\"Klára nám hlídala našeho ovčáka Foxe asi půl roku. I když náš pejsek trochu tahá, zvládala ho a dobře s ním vycházela. Za celou dobu jejího působení jsme neměli žádné důvody ke stížnostem. Její práce si jako rodina vážíme.\"<br>&nbsp;Petr N. (Německý ovčák X Aljašský malamut)"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"bfr77307z6i"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/1cd8846a1bb34998ad7fd897e542ed10_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/1cd8846a1bb34998ad7fd897e542ed10_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/1cd8846a1bb34998ad7fd897e542ed10_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/1cd8846a1bb34998ad7fd897e542ed10_s=860x_.JPG 860w, https://cdn.swbpg.com/t/26402/1cd8846a1bb34998ad7fd897e542ed10_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box" content={"\"Slečna Klára je moc milá, sympatická a především spolehlivá. O pejsky se postarala vždy s naprostou spokojeností, vždy po venčení pošle info, jak vše probíhalo a fotku spokojených pejsků. Jsem ráda, že se mohu v dnešní době na někoho spolehnout :).\" <br>Tereza Š. (Stafordšírští bulteriéři)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/o/26402/95fdc08a6e9b4276af84125d7a7c2514.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={""}>
              </Image>

              <Text className="text-box" content={"\"Potřeboval jsem rychle najít a zajistit večerní venčení pro mého psa. S Klárkou jsme se okamžitě dohodli a od té doby mohu klidně pracovat i v době, kdy bych měl být na procházce. Mohu jen s klidem konstatovat, že Elroy má tak zajištěné skvělé venčení.\" <br>Pavel D. (Rhodéský ridgeback)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/49adc179302942069a33461fa905ec58_s=660x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/49adc179302942069a33461fa905ec58_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/49adc179302942069a33461fa905ec58_s=660x_.JPG 660w"}>
              </Image>

              <Text className="text-box" content={"\"Děkujeme mnohokrát za Vaše služby stran hlídání a péče o psy. Máme s manželem náročná zaměstnání a měli jsme obavu, zda naši psi nebudou trávit nudné dny uvnitř našeho bytu. Díky Vaší službě víme, že je o ně dobře postaráno, psi si užívají Vaši společnost a my máme čas na práci bez starostí o ně. Skvělá práce, děkujeme!\" <br>Libuše A. (4 Welsh Corgi Pembroke)"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"vptt9cp04b"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/5c42d654b6b84c30bf0ba1dd90404529_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/5c42d654b6b84c30bf0ba1dd90404529_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/5c42d654b6b84c30bf0ba1dd90404529_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/5c42d654b6b84c30bf0ba1dd90404529_s=860x_.JPG 860w, https://cdn.swbpg.com/t/26402/5c42d654b6b84c30bf0ba1dd90404529_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box" content={"\"S hlídáním Klárky byla moje fenečka i já velmi spokojena. Potřebovala jsem hlídání na poslední chvíli a bez problémů jsem se domluvily. Je dochvilná a pejskovi se maximálně věnuje! Moje fenečka byla naprosto spokojená.\" <br>Nela B. (Whippet)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/984839a4c8154536aa6b448a0361f24d_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/984839a4c8154536aa6b448a0361f24d_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box" content={"\"Klára je na jedničku! Mám v ní jistotu a jsem klidná. Vím, že je o mého staforda, případně kocoura postaráno. Je šikovná a přizpůsobivá. Podle mě se jen tak něčeho nezalekne, nenechá vyvést z míry a nebojí se výzvy.\" <br>Karla V. (Americký stafordširský teriér)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/fd717d89900b484ba5f14aa7edae3aef_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/fd717d89900b484ba5f14aa7edae3aef_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box" content={"\"Naše dvě fenky Kláru milují. Ne jen, že to se psy umí a holky ji poslouchají, ale i se na ni můžeme stoprocentně spolehnout, když je s nimi sama u nás doma, což je pro nás stejně tak důležité, jako péče o naše psy. Hlídá u nás v domě i několik dní a vše je vždy v naprostém pořádku. Naše fenky i my jsem s Klárou nadmíru spokojeni!\" <br>Tom H. (Labradorský retrívr a kříženec)"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"b2i6r4uusog"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/95bc62b787874130a5e3e701b8ac0b99_s=660x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/95bc62b787874130a5e3e701b8ac0b99_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/95bc62b787874130a5e3e701b8ac0b99_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/95bc62b787874130a5e3e701b8ac0b99_s=860x_.JPG 860w"}>
              </Image>

              <Text className="text-box" content={"\"Klára mi hlídala pejska přes víkend a byla jsem moc spokojená. Sháněla jsem hlídání na poslední chvíli a ocenila jsem rychlou a bezproblémovou komunikaci. S mým pejskem se rychle skamarádili a domů se vrátil spokojený. Je vidět, že Klára má pejsky ráda. Průběžně mě informovala, jak jim to spolu jde a posílala fotky přes mms. Je spolehlivá a určitě jí můžu doporučit.\" <br>Petra M. (kříženec)\n\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Image src={"https://cdn.swbpg.com/o/26402/df60f83ccf2f4be8867ec8bcdeed2086.svg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Text className="text-box" content={"info@dogmom.cz\n\n<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}